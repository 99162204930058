{
  "app_name": "PDF-Tools",
  "or": "or",
  "dashboard": {
    "search": "What do you want to do?",
    "comming_soon": "Comming soon!",
    "merge": {
      "title": "Merge",
      "description": "Merge multiple PDF's into one file."
    },
    "pages": {
      "title": "Edit pages",
      "description": {
        "arrange": "Rearrange, remove or copy pages.",
        "edit": "Rotate or cut page to size."
      }
    },
    "compress": {
      "title": "Compress",
      "description": "Compress a PDF to save on filesize."
    },
    "sign": {
      "title": "Sign",
      "description": "Sign and fill in PDF forms."
    },
    "convert": {
      "title": "Convert",
      "description": "Convert PDF to image or plain text."
    },
    "extract": {
      "title": "Extract",
      "description": "Extract pictures and text from a PDF."
    },
    "edit": {
      "title": "Editor",
      "description": {
        "page_actions": "Rearrange, remove, insert & copy pages"
      }
    }
  },
  "open_file": {
    "choose_pdf": "Choose a PDF | Choose multiple PDF's",
    "or_drag_pdf": "or drag it here. | or drag them here."
  },
  "merge": {
    "page_title": "Merge",
    "completed": {
      "header": "Merging completed",
      "download_didnt_start": "If the download did not start automatically,",
      "click_here": "click here",
      "after_this": "After this",
      "start_over": "start over",
      "go_to_dashboard": "go to dashboard"
    },
    "merging": {
      "header": "Merge PDF files",
      "description": "Combine multiple PDF's in order into one file.",
      "view": "Change view",
      "view_actions": {
        "list": "List",
        "grid": "Grid"
      },
      "add_files": "Add more files",
      "merge_and_save": "Merge & download",
      "merge_and_edit": "Merge & edit further"
    }
  },
  "edit": {
    "page_title": "Edit",
    "zoom": {
      "fit_to_window": "Fit to window",
      "zoom_out": "Zoom out",
      "zoom_in": "Zoom in"
    }
  },
  "navbar": {
    "file": "File",
    "file_actions": {
      "open": "Open",
      "append": "Append PDF"
    },
    "page": "Page",
    "page_actions": {
      "duplicate": "Duplicate page",
      "remove": "Remove page"
    }
  }
}