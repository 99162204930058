{
  "app_name": "PDF-Tools",
  "or": "oder",
  "dashboard": {
    "search": "Was möchten Sie tun?",
    "comming_soon": "Kommt bald!",
    "merge": {
      "title": "Zusammenfügen",
      "description": "Mehrere PDF's zu einer Datei zusammenfügen."
    },
    "pages": {
      "title": "Seiten bearbeiten",
      "description": {
        "arrange": "PDF-Seiten verschieben, löschen oder duplizieren.",
        "edit": "Seiten drehen oder zuschneiden."
      }
    },
    "compress": {
      "title": "Komprimieren",
      "description": "PDF komprimieren um an Dateigröße zu sparen."
    },
    "sign": {
      "title": "Unterschreiben",
      "description": "PDF-Formular ausfüllen und unterschreiben."
    },
    "convert": {
      "title": "Umwandeln",
      "description": "PDF in Bild oder reinen Text umwandeln."
    },
    "extract": {
      "title": "Extrahieren",
      "description": "Bilder und Text aus PDF extrahieren."
    },
    "edit": {
      "title": "Editor",
      "description": {
        "page_actions": "Seiten verschieben, löschen, anhängen & duplizieren"
      }
    }
  },
  "open_file": {
    "choose_pdf": "PDF-Datei auswählen | Mehrere PDF-Dateien auswählen",
    "or_drag_pdf": "oder hier hin ziehen. | oder hier hin ziehen."
  },
  "merge": {
    "page_title": "Zusammenfügen",
    "completed": {
      "header": "Zusammenfügen abgeschlossen",
      "download_didnt_start": "Wenn der Download nicht automatisch gestartet wurde,",
      "click_here": "hier klicken",
      "after_this": "Danach",
      "start_over": "erneut Dateien zusammenfügen",
      "go_to_dashboard": "zum Dashboard"
    },
    "merging": {
      "header": "PDF-Dateien zusammenfügen",
      "description": "Füge mehrere PDF-Dateien in Reihenfolge zu einer einzelnen Datei zusammen.",
      "view": "Ansicht ändern",
      "view_actions": {
        "list": "Liste",
        "grid": "Galerie"
      },
      "add_files": "Weitere Dateien hinzufügen",
      "merge_and_save": "Zusammenfügen & herunterladen",
      "merge_and_edit": "Zusammenfügen & weiter bearbeiten"
    }
  },
  "edit": {
    "page_title": "Bearbeiten",
    "zoom": {
      "fit_to_window": "An Fenster anpassen",
      "zoom_out": "Rauszoomen",
      "zoom_in": "Reinzommen"
    }
  },
  "navbar": {
    "file": "Datei",
    "file_actions": {
      "open": "Öffnen",
      "append": "PDF anhängen"
    },
    "page": "Seite",
    "page_actions": {
      "duplicate": "Seite duplizieren",
      "remove": "Seite entfernen"
    }
  }
}