<template>
    <footer :class="{visible: visible}">
        &copy; {{ year }} {{ $t('app_name') }}
    </footer>
</template>

<script>
export default {
    name: 'footer-bar',
    props: {
        visible: Boolean,
    },
    data() {
        return {
            year: new Date().getFullYear(),
        };
    },
}
</script>

<style scoped>
footer {
    padding: 2rem 1rem;
    background-color: var(--dark-color);
    color: var(--light-color);
}

footer.visible {
    display: flex;
}
</style>