<template>
  <div id="dashboard">
    <nav-bar :title="$t('app_name')" />
    
    <section class="container">
      <div class="search-bar">
        <i class="las la-search"></i>

        <input type="text" v-model="searchString" autocomplete="off" :placeholder="$t('dashboard.search')">
        
        <i class="las la-times" @click="searchString = ''"></i>
      </div>

      <div class="operations">
        <div class="operation-container">
          <router-link to="merge" class="operation">
            <div class="title">
              <i class="las la-link"></i>

              <h2>{{ $t('dashboard.merge.title') }}</h2>
            </div>

            <div class="description">
              {{ $t('dashboard.merge.description') }}
            </div>
          </router-link>
        </div>
        
        <div class="operation-container">
          <router-link to="" class="operation comming-soon" :tooltip="$t('dashboard.comming_soon')">
            <div class="title">
              <i class="las la-copy"></i>

              <h2>{{ $t('dashboard.pages.title') }}</h2>
            </div>

            <div class="description">
              <ul>
                <li>{{ $t('dashboard.pages.description.arrange') }}</li>
                <li>{{ $t('dashboard.pages.description.edit') }}</li>
              </ul>
            </div>
          </router-link>
        </div>

        <div class="operation-container">
          <router-link to="" class="operation comming-soon" :tooltip="$t('dashboard.comming_soon')">
            <div class="title">
              <i class="las la-compress"></i>

              <h2>{{ $t('dashboard.compress.title') }}</h2>
            </div>

            <div class="description">
              {{ $t('dashboard.compress.description') }}
            </div>
          </router-link>
        </div>

        <div class="operation-container">
          <router-link to="" class="operation comming-soon" :tooltip="$t('dashboard.comming_soon')">
            <div class="title">
              <i class="las la-file-signature"></i>

              <h2>{{ $t('dashboard.sign.title') }}</h2>
            </div>

            <div class="description">
              {{ $t('dashboard.sign.description') }}
            </div>
          </router-link>
        </div>

        <div class="operation-container">
          <router-link to="" class="operation comming-soon" :tooltip="$t('dashboard.comming_soon')">
            <div class="title">
              <i class="las la-exchange-alt"></i>

              <h2>{{ $t('dashboard.convert.title') }}</h2>
            </div>

            <div class="description">
              {{ $t('dashboard.convert.description') }}
            </div>
          </router-link>
        </div>

        <div class="operation-container">
          <router-link to="" class="operation comming-soon" :tooltip="$t('dashboard.comming_soon')">
            <div class="title">
              <i class="las la-object-group"></i>

              <h2>{{ $t('dashboard.extract.title') }}</h2>
            </div>

            <div class="description">
              {{ $t('dashboard.extract.description') }}
            </div>
          </router-link>
        </div>
        
        <div class="operation-container">
          <router-link to="edit" class="operation">
            <div class="title">
              <i class="las la-pencil-ruler"></i>

              <h2>{{ $t('dashboard.edit.title') }}</h2>
            </div>

            <div class="description">
              <ul>
                <li>{{ $t('dashboard.edit.description.page_actions') }}</li>
              </ul>
            </div>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'

export default {
  name: 'dashboard',
  components: {
    NavBar,
  },
  data() {
    return {
      searchString: '',
    };
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped>
.fab {
  width: 4rem;
  height: 4rem;
  font-size: 1.6rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 500;
  background-color: var(--primary-color);
  color: var(--light-color);
  box-shadow: 3px 3px 12px 0 rgba(72,191,132,0.4);
}

.search-bar {
  position: relative;
  margin-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .search-bar {
    width: calc(100% / 3 * 2);
    margin-left: auto;
    margin-right: auto;
  }
}

.search-bar input {
  font-size: 1.2rem;
  border-radius: .5rem;
  padding: .75rem 3.5rem;
  width: 100%;
}

.search-bar i {
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.search-bar .la-search {
  pointer-events: none;
  left: 1rem;
}

.search-bar .la-times {
  opacity: 0;
  visibility: hidden;
  right: 1rem;
  transition: opacity .2s ease, visibility .2s ease;
}

.search-bar input:not(:placeholder-shown) + .la-times {
  opacity: 1;
  visibility: visible;
}

.operations {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -.5rem;
}

.operation-container {
  padding: 0 .5rem;
  flex: 0 0 100%;
  min-width: 0;
  margin-bottom: 1rem;
  transition: transform .2s ease;
}

@media (min-width: 576px) {
  .operation-container {
    flex: 0 0 50%;
  }
}

@media (min-width: 992px) {
  .operation-container {
    flex: 0 0 calc(100% / 3);
  }
}

.operation {
  display: block;
  background-color: var(--light-color);
  border-radius: .5rem;
  padding: .75rem 1rem;
  color: var(--text-default-color);
  text-decoration: none;
  height: 100%;
  transition: background-color .2s ease;
}

.operation:hover {
  background-color: var(--light-hover-color);
}

.operation.comming-soon {
  background-color: var(--background-color);
  color: var(--text-muted-color);
  border: 2px dashed var(--text-muted-color);
  cursor: auto;
}

.operation .title {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  transition: color .2s ease;
}

.operation .title h2 {
  margin-bottom: 0;
}

.operation .title i {
  font-size: 2rem;
  margin-right: .5rem;
}
</style>