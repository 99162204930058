import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from './views/Dashboard.vue'

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        showFooter: true,
      },
    },
    {
      path: '/merge',
      name: 'merge',
      component: () => import('./views/Merge.vue'),
      meta: {
        showFooter: true,
      },
    },
    {
      path: '/edit',
      name: 'edit',
      component: () => import('./views/Edit.vue'),
      meta: {
        showFooter: false,
      },
    },
  ]
})
