<template>
  <header>
    <router-link to="/" class="logo">
      <img src="@/assets/images/logo.svg">
    </router-link>

    <div class="nav-bar-inner">
      <div class="page-title">
        <span>{{ title }}</span>
      </div>

      <div v-if="options && options.length" class="options">
        <div v-for="group in options" :key="group.key" class="dropdown">
          <button>{{ $t(`navbar.${group.key}`) }}</button>
          
          <div v-if="group.actions" class="dropdown-menu">
            <button v-for="action in group.actions" :key="action.key" @click="onOptionSelected(group.key, action.key)">
              <span class="icon-holder">
                <i v-if="action.icon" :class="action.icon"></i>
              </span>
              
              <span>{{ $t(`navbar.${group.key}_actions.${action.key}`) }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'nav-bar',
  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
    options: {
      type: Array,
      default: [],
      required: false,
    },
  },
  methods: {
    onOptionSelected(group, action) {
      this.$emit('optionSelected', group, action);
    }
  },
}
</script>

<style scoped>
header {
  height: 4.8rem;
  background-color: var(--primary-color);
  color: var(--light-color);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  z-index: 400;
  box-shadow: 0 3px 12px 0 rgba(72,191,132,0.4);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.logo img {
  height: 2.5rem;
  width: auto;
}

.nav-bar-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-title {
  font-weight: 300;
  font-size: 1.25rem;
  padding: .5rem 0;
}

.options {
  margin: auto -1px 0 calc(-1px - .75rem);
  display: flex;
  align-items: flex-end;
  padding: .25rem 0;
}

.dropdown {
  padding: 0 1px;
}

.dropdown > button {
  background-color: transparent;
  color: var(--light-color);
}

.dropdown > button:hover, .dropdown > button:focus {
  background-color: rgba(0,0,0,.1);
}

.options-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.options-box button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.25rem;
  padding-right: 0;
  padding-left: 0;
  font-size: 1.6rem;
  background-color: transparent;
  color: var(--light-color);
}

.options-box .exit {
  margin-left: 0;
  margin-right: auto;
}
</style>