<template>
  <div id="app-view">
    <div class="content">
      <router-view v-slot="{Component}">
          <component :is="Component" />
      </router-view>
    </div>

    <footer-bar :visible="showFooter" />
  </div>
</template>

<script>
import FooterBar from '@/components/FooterBar.vue';

export default {
  name: 'App',
  components: {
    FooterBar,
  },
  data() {
    return {
      showFooter: true,
    };
  },
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      this.showFooter = to.meta.showFooter ?? true;

      next();
    });
  }
}
</script>

<style>
:root {
  --background-color: #e5f0f0;
  --text-muted-color: #3c6767;
  --text-default-color: #2a4747;
  --primary-color: #48bf84;
  --primary-hover-color: #3aa771;
  --secondary-color: #61d095;
  --tertiary-color: #439775;
  --light-color: #f2f7f7;
  --light-hover-color: #dae8e8;
  --light-dark-color: #3c6767;
  --dark-color: #2a4747;
  --error-color: #d23c37;
  --border-light-color: #c1c6c6;
}

::selection {
  background-color: rgba(72, 191, 132, .9);
  color: var(--light-color);
}

*, ::before, ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#app-view {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  background-color: var(--background-color);
  color: var(--text-default-color);
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  -webkit-tap-highlight-color: transparent;
}

#app-view > .content {
  flex-grow: 1;
  
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  margin-bottom: 1rem;
}

hgroup > :first-child {
  margin-bottom: 0;
}

p {
  margin-bottom: 1rem;
}

ul {
  padding-left: 1.25rem;
}

input, button, select {
  font-family: 'Poppins', sans-serif;
  padding: .25rem .75rem;
  border: none;
  border-radius: .5rem;
  font-size: 1rem;
  outline: 0;
  background-color: var(--light-color);
  color: var(--text-default-color);
  position: relative;
  transition: background-color .2s ease, color .2s ease;
}

button {
  cursor: pointer;
  user-select: none;
}

button:hover, button:focus {
  background-color: var(--light-hover-color);
}

.btn-large {
  padding: .5rem 1rem;
}

.btn-inline {
  background: none!important;
  border-radius: 0;
  border: none;
  padding: 0;
  font-size: inherit;
  color: inherit;
  font-weight: 500;
  text-decoration: inherit;
}

.btn-inline:hover,
.btn-inline:focus {
  color: var(--primary-color);
}

.btn-round {
  padding: 0;
  width: 2rem;
  height: 2rem;
  line-height: 1;
  border-radius: 50%;
}

.btn-primary {
  background-color: var(--primary-color);
  color: var(--light-color);
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: var(--primary-hover-color);
}

input::placeholder {
  color: var(--text-muted-color);
}

[tooltip] {
  position: relative;
}

[tooltip]::after {
  content: attr(tooltip);
  position: absolute;
  background-color: var(--dark-color);
  color: var(--light-color);
  padding: .25rem .75rem;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(20%);
  font-size: .8rem;
  border-radius: .5rem;
  user-select: none;
  pointer-events: none;
  opacity: 0;
  transition: transform .2s ease, opacity .2s ease;
  z-index: 1;
}

[tooltip]:hover::after, [tooltip]:focus::after {
  transform: translateX(-50%) translateY(0);
  opacity: 1;
}

.checkbox-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.checkbox-container input {
  padding: 0;
  margin: 0;
  width: 0;
  height: 0;
  visibility: hidden;
}

.checkbox-container .checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.2rem;
  width: 1.2rem;
  border: 2px solid;
  border-radius: .25rem;
  border-color: var(--text-default-color);
  color: var(--text-default-color);
  transition: color .2s ease;
}

.checkbox-container input[readonly] + .checkbox,
.checkbox-container input[disabled] + .checkbox {
  border-color: var(--text-muted-color);
  color: var(--text-muted-color);
}

.checkbox-container input:not(:checked) + .checkbox {
  color: transparent;
}

.page-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: calc(4.8rem + .5rem) .5rem .5rem;
  user-select: none;
}

.page-head h1 {
  font-size: 1.6rem;
  margin: 0;
}

.dropdown {
  position: relative;
  overflow: visible;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  padding: .5rem 0;
  background: var(--light-color);
  border-radius: .5rem;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0 rgba(0,0,0,0.15);
  opacity: 0;
  visibility: hidden;
  z-index: 800;
  transition: opacity .2s ease, visibility .2s ease;
}

.dropdown:focus-within::before {
  opacity: 1;
}

.dropdown:focus-within .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.dropdown-menu > button {
  white-space: nowrap;
  padding: .25rem .75rem;
  width: 100%;
  font-size: 1rem;
  background: transparent;
  border: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  color: var(--text-default-color);
}

.dropdown-menu > button:hover, .dropdown-menu > button:focus {
  background-color: var(--light-hover-color);
}

.dropdown-menu > button .icon-holder {
  width: 1.2rem;
  font-size: 1.2rem;
  margin-right: .5rem;
}

.container {
  max-width: 1200px;
  width: 100%;
  padding: 2rem 1rem;
  margin: 0 auto;
}

.page-render-canvas {
  position: absolute;
  user-select: none;
  opacity: 0;
  pointer-events: none;
  transform: scale(0);
  transform-origin: top left;
}
</style>